export const FILTERING_OPERATORS = [":", "!:", "=", "!="];
export const LINKS = {
  BUG_TICKET_TEMPLATE:
    "https://t.corp.amazon.com/create/templates/0405360c-25f1-4cf4-ad2d-9e5123405e94",
  FEATURE_TICKET_TEMPLATE:
    "https://t.corp.amazon.com/create/templates/80432b95-29f9-4c23-9d19-410cd598e781",
  NEW_TEMPLATE_TICKET_TEMPLATE:
    "https://t.corp.amazon.com/create/templates/8afc7323-3e33-4ab9-a221-67aed9b21f6f",
  PERMISSIONS_UPDATE_TICKET_TEMPLATE:
    "https://t.corp.amazon.com/create/templates/0f5d2e63-2a8f-4bc2-b460-bcb99f5271b3",
};
